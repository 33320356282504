.cjs-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.cjs-photo {
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}